import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import domReady from 'domready';
import NotificationList from 'shared/components/NotificationList';
import FeedbackList from 'shared/components/FeedbackList';

/**
 * Renderöi bäkkäriltä tulevat notifikaatiot ja viestit frontille.
 */
export default (messages) => {
    const notifications = _.get(messages, 'notifications', []);
    const feedbacks = _.get(messages, 'feedbacks', []);

    // domReady koska yläpalkin marginaalin määrä oli 0 ennen kuin DOM oli valmis. Tätä käytetään näyttämään
    // notifikaatiot oikeassa paikassa.
    domReady(function() {
        const rootNode = document.getElementById('root');

        if (notifications.length) {
            // Luodaan main-tagin sisään bäkkäriltä tuleville notifikaatioille oma alue.
            const notificationListNode = document.createElement('div');
            rootNode.insertBefore(notificationListNode, null);

            ReactDOM.render(React.createElement(NotificationList, {
                notifications,
            }), notificationListNode);
        }

        // Luodaan main-tagin sisään bäkkäriltä tuleville feedbackeille oma alue.
        const feedbackListNode = document.createElement('div');
        if (rootNode) {
            rootNode.insertBefore(feedbackListNode, rootNode.firstChild);
            ReactDOM.render(React.createElement(FeedbackList, {
                feedbacks,
            }), feedbackListNode);
        }
    });
};
